// Local
// export const FRONT_URL = "http://localhost:3000/";
// export const API_URL = "http://localhost:2053/";
// Testnet
// export const FRONT_URL = "https://flutterpads-admin.maticz.in/";
// export const API_URL = "https://backend-jettolet.maticz.in/";
// //Mainnet
export const FRONT_URL = "https://bot.rentformers.com/"
export const API_URL = "https://api.flutterpads.com/";
export const GOOGLE_MAP_API_KEY = "AIzaSyB5eMv-zon1iim5Ba1TCKxHl_p3iMTWPMQ";
export const toastOptions = {
    style: {
      border: '1px solid #082e54',
      padding: '16px',
      color: '#082e54',
    },
    iconTheme: {
      primary: '#fbc108',
      secondary: '#082e53',
    },
  }
//export const FRONT_URL = "http://43.204.99.101:9097/";

